@font-face {
font-family: '__inter_07e2d6';
src: url(/_next/static/media/7ca59b69c5914209-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__inter_07e2d6';
src: url(/_next/static/media/7ca59b69c5914209-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__inter_07e2d6';
src: url(/_next/static/media/f53ddc1e29b5dc42-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__inter_07e2d6';
src: url(/_next/static/media/5b8409335a5b011c-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__inter_Fallback_07e2d6';src: local("Arial");ascent-override: 96.63%;descent-override: 24.06%;line-gap-override: 0.00%;size-adjust: 100.25%
}.__className_07e2d6 {font-family: '__inter_07e2d6', '__inter_Fallback_07e2d6'
}

